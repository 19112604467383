body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.input_opt {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-block: 30px;
}

.otp_input {
  width: 40px;
  padding-inline: 0px;
  text-align: center;
}
.password_field{
  position: relative;
}
.password-toggle-button{
  position: absolute;
  right: 10px;
  top: 36px;
  user-select: none;
}
.btn_close_pop{
  background-color: transparent;
  border: 0px;
  font-size: 25px;
  color: #5a6a85 !important;
}