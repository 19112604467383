/* .jb_border{
    border: 0px !important;
    border-bottom:1px solid #dfe5ef;
} */
.custom_card_body{
    padding-block: 0px;
}
.custom_card_body_password{
    padding-block-start: 0px;
}
.employee_type{
    display: flex;
    gap: 20px;
    align-items: center;
}
.badge-width {
    width: 80px; /* Adjust the width as needed */
    display: inline-block; /* Ensures the width is applied */
    text-align: center; /* Optional: Center the text */
  }
.badge-width-status {
    width: 115px;
    display: inline-block; 
    text-align: center; 
  }
  .design_img_div{
    height: 75px;
  }
  .custom_img_design{
    height: 100% !important;
    width: auto;
  }
  .pdf_div{
    font-size: 22px;
    color: red !important;
    
  }